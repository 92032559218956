import { createStore } from 'vuex'
import { authApi } from '../api'

export default createStore({
  state: {
    token: '',
    userType: '',
    loadingShow: false,
    msg: null,
    im: null,
    meetingEvent: null,
    meetingeEnterInfo: null,
    meetingUser: null,
    updateKey: 1,
    serverDelay: 0,
    liveIsStop: false,
    eventExpress: '',
    countStartFlag: false
  },
  getters: {
    token: state => state.token,
    userType: state => state.userType,
    loadingShow: state => state.loadingShow,
    msg: state => state.msg,
    im: state => state.im,
    meetingEvent: state => state.meetingEvent,
    meetingeEnterInfo: state => state.meetingeEnterInfo,
    meetingUser: state => state.meetingUser,
    updateKey: state => state.updateKey,
    serverDelay: state => state.serverDelay,
    liveIsStop: state => state.liveIsStop,
    eventExpress: state => state.eventExpress,
    countStartFlag: (state) => state.countStartFlag,
  },
  mutations: {
    setEventExpress(state, data) {
      // debugger
      state.eventExpress = data
    },
    addUpdateKey(state) {
      // debugger
      state.updateKey++
    },
    setLiveIsStop(state, data) {
      // debugger
      state.liveIsStop = data
    },
    setToken(state, data) {
      // debugger
      state.token = data
    },
    setUserType(state, data) {
      // debugger
      state.userType = data
    },
    setLoading(state, data) {
      // debugger
      state.loadingShow = data
    },
    setMsg(state, data) {
      // debugger
      state.msg = data
    },
    setIm(state, data) {
      // debugger
      state.im = data
    },
    setMeetingEvent(state, data) {
      // debugger
      state.meetingEvent = data
    },
    setMetingeEnterInfo(state, data) {
      // debugger
      state.meetingeEnterInfo = data
    },
    setMeetingUser(state, data) {
      // debugger
      state.meetingUser = data
    },
    setCountStartFlag(state, data) {
      state.countStartFlag = data;
    },
    // eslint-disable-next-line no-unused-vars
    async checkDelay(state) {
      const average = arr => {
        return arr.reduce((acc, val) => acc + val, 0) / arr.length
      }
      const delayList = []
      const maxCount = 100
      let reqCount = 0
      while (delayList.length < 5 && reqCount < maxCount) {
        const startTime = new Date().getTime()
        await authApi
          .syncTime()
          .then(res => {
            const endTime = new Date().getTime()
            const serverTime = new Date(res.data.now).getTime()
            const delayTime = endTime - (serverTime - (endTime - startTime) / 2)
            console.log(delayTime)
            console.log(endTime - startTime, serverTime, endTime)
            if (endTime - startTime < 500) {
              delayList.push(delayTime)
              // state.serverDelay = Math.round(delayTime)
            }
          })
          .catch(err => {
            console.error(err)
          })
      }
      if (delayList.length < 5) {
        setTimeout(() => {
          this.dispatch('checkDelay')
        }, 5000)
      } else {
        state.serverDelay = Math.round(average(delayList))
      }
    }
  },
  actions: {
    setEventExpress({ commit }, data) {
      // debugger
      commit('setEventExpress', data)
    },
    checkDelay({ commit }) {
      // debugger
      commit('checkDelay')
    },
    addUpdateKey({ commit }) {
      // debugger
      commit('addUpdateKey')
    },
    setLiveIsStop({ commit }, data) {
      // debugger
      commit('setLiveIsStop', data)
    },
    setToken({ commit }, data) {
      // debugger
      commit('setToken', data)
      // if (data) {
      //   commit('checkDelay')
      // }
    },
    setUserType({ commit }, data) {
      // debugger
      commit('setUserType', data)
    },
    setLoading({ commit }, data) {
      // debugger
      commit('setLoading', data)
    },
    setMsg({ commit }, data) {
      // debugger
      commit('setMsg', data)
    },
    setIm({ commit }, data) {
      // debugger
      commit('setIm', data)
    },
    setMeetingEvent({ commit }, data) {
      // debugger
      commit('setMeetingEvent', data)
    },
    setMetingeEnterInfo({ commit }, data) {
      // debugger
      commit('setMetingeEnterInfo', data)
    },
    setMeetingUser({ commit }, data) {
      // debugger
      commit('setMeetingUser', data)
    },
    setCountStartFlag({ commit }, data) {
      commit("setCountStartFlag", data);
    },
  },
  modules: {}
})
