import globalConfig from '../../config/config'
export default function(api) {
  const userApiBaseUrl = globalConfig.userApiBaseUrl
  const liverApiBaseUrl = globalConfig.liverApiBaseUrl
  return {
    oauthuser(data) {
      return api.post(userApiBaseUrl + '/auth/oauthuser', data)
    },
    authTwitterToken(data) {
      return api.post(userApiBaseUrl + '/auth/twitter/token', data)
    },
    authLineToken(data) {
      return api.post(userApiBaseUrl + '/auth/line/token', data)
    },
    login(data) {
      return api.post(userApiBaseUrl + '/auth/login', data, { noLoading: true })
    },
    email(data) {
      return api.post(userApiBaseUrl + '/auth/email', data)
    },
    phone(data) {
      return api.post(userApiBaseUrl + '/auth/phone', data)
    },
    verify(data) {
      return api.post(userApiBaseUrl + '/auth/verify', data)
    },
    regist(data) {
      return api.post(userApiBaseUrl + '/auth/regist', data)
    },
    resetpassword(data) {
      return api.post(userApiBaseUrl + '/resetpassword', data)
    },
    resetpasswordEmail(data) {
      return api.post(userApiBaseUrl + '/resetpassword/email', data)
    },
    resetpasswordPhone(data) {
      return api.post(userApiBaseUrl + '/resetpassword/phone', data)
    },
    events() {
      return api.get(userApiBaseUrl + '/events')
    },
    eventsJoin(data, id) {
      return api.post(userApiBaseUrl + `/events/${id}/join`, data, {
        shouldJWT: true
      })
    },
    // アンケート
    getEnquete(id) {
      return api.get(userApiBaseUrl + `/enquete/${id}`, { shouldJWT: true })
    },
    saveEnquete(id, data) {
      return api.post(userApiBaseUrl + `/enquete/${id}/answer`, data, {
        shouldJWT: true
      })
    },

    liversLiving() {
      return api.get(userApiBaseUrl + '/livers/living')
    },
    gifts() {
      return api.get(userApiBaseUrl + '/gifts')
    },
    eventsDetail(id) {
      return api.get(userApiBaseUrl + `/events/${id}`)
    },
    eventsGift(id, data) {
      return api.post(userApiBaseUrl + `/events/${id}/gift`, data, {
        shouldJWT: true
      })
    },
    livers() {
      return api.get(userApiBaseUrl + '/livers', { shouldJWT: true })
    },
    liversEvent(id, data) {
      return api.get(userApiBaseUrl + `/livers/${id}/events`, {
        shouldJWT: true,
        params: data
      })
    },
    profile() {
      return api.get(userApiBaseUrl + '/profile', { shouldJWT: true })
    },
    putProfile(data) {
      return api.put(userApiBaseUrl + '/profile', data, { shouldJWT: true })
    },
    refresh(data) {
      // data["shouldJWT"] = true
      return api.post(userApiBaseUrl + '/auth/refresh', data)
    },
    pricingList() {
      return api.get(userApiBaseUrl + '/pricing')
    },

    profilePoints(data) {
      return api.get(userApiBaseUrl + '/profile/points', {
        shouldJWT: true,
        params: data
      })
    },
    elepayCreate(data) {
      return api.post(userApiBaseUrl + '/elepay/create', data, {
        shouldJWT: true
      })
    },
    liversFollow(id) {
      const data = {}
      return api.post(userApiBaseUrl + `/livers/${id}/follow`, data, {
        shouldJWT: true
      })
    },
    joined(data) {
      return api.get(userApiBaseUrl + '/joined', {
        shouldJWT: true,
        noLoading: true,
        params: data
      })
    },
    joinedNextTicket(id) {
      return api.get(userApiBaseUrl + `/joined/${id}/recentTicket`, {
        shouldJWT: true
      })
    },
    devicetest() {
      return api.get(userApiBaseUrl + `/devicetest/user`, {
        noLoading: true
      })
    },
    devicetestSave(data) {
      return api.post(userApiBaseUrl + `/devicetest/save`, data, {
        noLoading: true
      })
    },
    logSave(data) {
      return api.post(userApiBaseUrl + `/log/save`, data, {
        noLoading: true,
        shouldJWT: true
      })
    },
    chatroom(id) {
      const data = {}
      return api.post(userApiBaseUrl + `/events/${id}/chatroom`, data, {
        shouldJWT: true
      })
    },
    waitingroom(id) {
      const data = {}
      return api.post(userApiBaseUrl + `/events/${id}/waitingroom`, data, {
        shouldJWT: true,
        noLoading: true
      })
    },
    eventsBeforeCount(id) {
      return api.get(userApiBaseUrl + `/events/${id}/beforecount`, {
        shouldJWT: true,
        noLoading: true
      })
    },
    eventsTalk(id) {
      return api.put(userApiBaseUrl + `/events/${id}/talk`, undefined, {
        shouldJWT: true,
        noLoading: true
      })
    },
    ticketsCertificate(eventId, ticketId, data) {
      return api.post(
        userApiBaseUrl + `/joined/${eventId}/tickets/${ticketId}/certificate`,
        data,
        { shouldJWT: true }
      )
    },
    ticketsPhotos(eventId, ticketId, data) {
      return api.post(
        userApiBaseUrl + `/joined/${eventId}/tickets/${ticketId}/photos`,
        data,
        { shouldJWT: true }
      )
    },
    ticketsPhotosGet(eventId, ticketId) {
      return api.get(
        userApiBaseUrl + `/joined/${eventId}/tickets/${ticketId}/photos`,
        {
          shouldJWT: true
        }
      )
    },
    ticketsDetail(eventId, ticketId) {
      return api.get(
        userApiBaseUrl + `/joined/${eventId}/tickets/${ticketId}`,
        {
          shouldJWT: true
        }
      )
    },
    ticketList(eventId) {
      return api.get(userApiBaseUrl + `/joined/${eventId}/tickets`, {
        shouldJWT: true
      })
    },
    contact(data) {
      return api.post(userApiBaseUrl + `/contact`, data, { shouldJWT: true })
    },
    syncTime() {
      return api.get(userApiBaseUrl + `/sync/time`, {
        shouldJWT: true,
        noLoading: true
      })
    },
    // liver
    LiverLogin(data) {
      return api.post(liverApiBaseUrl + '/login', data)
    },
    LiverGetProfile() {
      return api.get(liverApiBaseUrl + '/profile', { shouldJWT: true })
    },
    LiverPutProfile(data) {
      data['shouldJWT'] = true
      return api.put(liverApiBaseUrl + '/profile', data)
    },
    eventsLiving(data, id) {
      data['shouldJWT'] = true
      return api.post(liverApiBaseUrl + `/events/${id}/living`, data)
    }
  }
}
