import { createRouter, createWebHistory } from '@ionic/vue-router'

// クライアント
import User from '../views/user/User.vue'
// meeting
import MeetingHome from '../views/user/handshakeMeeting/MeetingHome.vue'
import store from '../store'

// meta:{
//   shouldLogin:false, 登録チェックか
//   userType:"c" ユーザーページ：c,アイドルページ:b
// }
const routes = [
  {
    path: '/',
    redirect: '/entrance'
  },
  {
    path: '/entrance',
    name: 'Entrance',
    component: () =>
      import(
        /* webpackChunkName: "Entrance" */ '../views/user/index/Entrance.vue'
      ),
    meta: {
      publicPage: true,
      shouldLogin: false,
      userType: 'c'
    }
  },
  {
    path: '/devicetest',
    name: 'DeviceCheck',
    component: () =>
      import(
        /* webpackChunkName: "DeviceCheck" */ '../views/user/index/DeviceCheck.vue'
      ),
    meta: {
      publicPage: true,
      shouldLogin: false,
      userType: 'c'
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () =>
      import(/* webpackChunkName: "Error" */ '../views/user/index/Error.vue'),
    meta: {
      publicPage: true,
      shouldLogin: false,
      userType: 'c'
    }
  },
  {
    path: '/user',
    component: User,
    children: [
      {
        path: 'meeting/:id',
        component: MeetingHome,
        children: [
          {
            path: 'streaming',
            name: 'Streaming',
            component: () =>
              import(
                /* webpackChunkName: "Streaming" */ '../views/user/handshakeMeeting/Streaming.vue'
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              userType: 'c',
              meeting: true
            }
          },
          {
            path: 'chatroom',
            name: 'ChatRoom',
            component: () =>
              import(
                /* webpackChunkName: "ChatRoom" */ '../views/user/handshakeMeeting/ChatRoom.vue'
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              userType: 'c',
              meeting: true
            }
          },
          {
            path: 'inquiry',
            name: 'Inquiry',
            component: () =>
              import(
                /* webpackChunkName: "Inquiry" */ '../views/user/handshakeMeeting/Inquiry.vue'
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              meeting: true,
              userType: 'c'
            }
          },
          {
            path: 'streamingend',
            name: 'StreamingEnd',
            component: () =>
              import(
                /* webpackChunkName: "StreamingEnd" */ '../views/user/handshakeMeeting/StreamingEnd.vue'
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              userType: 'c',
              meeting: true
            }
          }
        ]
      }
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: '/error' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // TODO:test
  // store.dispatch(
  //   'setToken',
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyMyIsIm5iZiI6MTYyMDM3MjEwMSwibmlja25hbWUiOiJhdmF0YXIiLCJpc3MiOiJ3ZXNob3ciLCJpZCI6IjIzIiwiZXhwIjoxNjIyOTY0MTAxLCJlbWFpbCI6ImF2YXRhckBnbWFpbC5jb20ifQ.dX7eIBw2gWy81MFHxa4H5fK5jDM1jvyrIB6wSgzUNL6IFNYDw4bVpDLDAbWmT_3pAyNRlhVaXNH0RPIy6Ju3YoXUiMYnfLfsdALQekWZddBqfsyDPIjjo6YsXcPJzN--TnjeuTSIUSDuL2UmO8se9Cx-Z9ayX-J_jMqPOt-urjdn4hXELLEz4gwFBNy--qsQ9t_6430VY7_8Ge2-iX4s-05fGdoIyOXx4ls_BxU8ae-AoafKHDl1Zn7xf_zpR8_TowjVPMRLcAUOBRrmIj9M7zzAVYHPxPGLwyZ0-lHk4PEJyluLhNjK63-arIVNP6ClfhTG9fBbxpgtYKqtM9HXfw'
  // )
  // store.dispatch('setUserType', 'c')

  console.log(to, store.getters.token)
  const publicPage = to.meta.publicPage
  const shouldLogin = to.meta.shouldLogin
  const routeUserType = to.meta.userType
  const token = store.getters.token
  const userType = store.getters.userType
  if (!publicPage) {
    if (shouldLogin) {
      if (!token) {
        router.replace({ name: 'Error' })
      } else {
        if (routeUserType !== userType) {
          switch (userType) {
            case 'c':
              router.replace({ name: 'Error' })
              break
            case 'b':
              router.replace({ name: 'Error' })
              break
            default:
              break
          }
        }
      }
    } else {
      if (token) {
        // TODO: B と C の判断
        switch (userType) {
          case 'c':
            router.replace({ name: 'IdolPage' })
            break
          case 'b':
            router.replace({ name: 'IdolHome' })
            break
          default:
            break
        }
      }
    }
  }
  // setTimeout(() => {
  //   const IsItABackButton = window.popStateDetected
  //   window.popStateDetected = false
  //   if (IsItABackButton && from.meta.meeting) {
  //     next(false)
  //     return ''
  //   }
  //   next()
  // }, 0)

  next()
})
export default router
