import store from '../store'
import { authApi } from '../api'
import router from '../router'

export const banner =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAATCAYAAAAao7T0AAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV/TSkUqHewg0iFDdbIgVcRRqlgEC6Wt0KqDyaVf0MSQpLg4Cq4FBz8Wqw4uzro6uAqC4AeIm5uToouU+L+k0CLGg+N+vLv3uHsHCK06U83ABKBqlpFNJcVCcUUMviKAKPxIICwxU0/nFvLwHF/38PH1Ls6zvM/9OQaVkskAn0g8y3TDIl4nnt60dM77xBFWlRTic+Jxgy5I/Mh12eU3zhWHBZ4ZMfLZOeIIsVjpYbmHWdVQiaeIY4qqUb5QcFnhvMVZrTdY5578haGStpzjOs0oUlhEGhmIkNFADXVYiNOqkWIiS/tJD/+I48+QSyZXDYwc89iACsnxg//B727N8mTCTQolgb4X2/4YBYK7QLtp29/Htt0+AfzPwJXW9W+0gJlP0ptdLXYEhLeBi+uuJu8BlzvA8JMuGZIj+WkK5TLwfkbfVASGboGBVbe3zj5OH4A8dbV0AxwcAmMVyl7zeHd/b2//nun09wOGw3KveZXC2QAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+UEHAM1AbJiIg8AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAGUlEQVRIx+3BMQEAAADCoPVPbQo/oAAA4G4KdwABqaZMVwAAAABJRU5ErkJggg=='

export const commonLoginAfter = function(router) {
  router.replace({
    name: 'SettingProfile'
  })
}

let callbackList = []
let refreshJWTIng = false
export const addRefreshJWT = function(callback) {
  callbackList.push(callback)
  if (!refreshJWTIng) {
    refreshJWT()
  }
}
export const initRefreshJWT = function() {
  callbackList = []
  refreshJWTIng = false
}
// jwtをリフレッシュ
export const refreshJWT = function() {
  refreshJWTIng = true
  const jwt = store.getters.token
  authApi
    .refresh({ jwt: jwt })
    .then(res => {
      commonLogin(res.data.jwt, store.getters.userType)
      if (callbackList) {
        callbackList.forEach(fn => {
          try {
            fn()
          } catch (error) {
            console.error(error)
          }
        })
      }
      initRefreshJWT()
    })
    .catch(() => {
      // 認証エラー
      // token削除
      deleteToken()
      router.replace({
        name: 'Entrance'
      })
      initRefreshJWT()
    })
}
export const encodeParams = function(params) {
  return btoa(encodeURIComponent(JSON.stringify(params)))
}

export const decodeParams = function(text) {
  return JSON.parse(decodeURIComponent(atob(text)))
}

export const logout = function(router, name) {
  // token削除
  deleteToken()
  router.replace({
    name: name
  })
}

export const deleteToken = function() {
  // token削除
  store.dispatch('setToken', '')
  localStorage.removeItem('token')
  store.dispatch('setUserType', '')
  localStorage.removeItem('userType')
}

export const commonLogin = function(jwt = '', userType = 'c', callback) {
  store.dispatch('setToken', jwt)
  localStorage.setItem('token', jwt)
  store.dispatch('setUserType', userType)
  localStorage.setItem('userType', userType)
  if (callback) {
    callback()
  }
}

let loadingCount = 0
let loadingTimer = null

const startLoading = () => {
  store.dispatch('setLoading', true)
}

const endLoading = () => {
  store.dispatch('setLoading', false)
}

export const showLoading = () => {
  clearTimeout(loadingTimer)
  loadingTimer = setTimeout(() => {
    loadingCount = 1
    hideLoading()
  }, 5000)
  if (loadingCount === 0) {
    startLoading()
  }
  loadingCount += 1
}

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return
  }
  loadingCount -= 1
  if (loadingCount === 0) {
    clearTimeout(loadingTimer)
    endLoading()
  }
}

export const creatColorByNickName = NickName => {
  // nick name to code
  let id = 0
  for (var i = 0; i < NickName.length; i++) {
    id += NickName.charCodeAt(i)
  }
  id = id % 100
  const h = id * (360 / 100)
  const s = 80
  const l = 30
  return hslToHex(h, s, l)
}
export const hslToHex = (h, s, l) => {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `${f(0)}${f(8)}${f(4)}`
}
