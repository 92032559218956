<template>
  <ion-page>
    <ion-router-outlet />
  </ion-page>
</template>

<script>
import { IonRouterOutlet, IonPage } from '@ionic/vue'
import {
  defineComponent,
  ref,
  watch,
  computed,
  onUnmounted,
  onMounted,
  nextTick
} from 'vue'
// import TWebLive from 'tweblive'
import { useRoute, useRouter } from 'vue-router'
import { authApi } from '../../../api'
import { addRefreshJWT } from '../../../utils/utils'
import store from '../../../store'
import TIM from 'tim-js-sdk'
import {
  showLoading,
  hideLoading,
  creatColorByNickName
} from '../../../utils/utils'

export default defineComponent({
  name: 'MeetingHome',
  components: {
    IonRouterOutlet,
    IonPage
  },
  created() {
    this.$store.dispatch('checkDelay')
  },
  computed: {
    im() {
      return this.$store.getters.im
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const id = ref(route.params.id)
    const view = ref(null)
    const updateKey = computed(() => store.getters.updateKey)
    const liveIsStop = computed(() => store.getters.liveIsStop)
    // setTimeout(() => {
    //   store.dispatch('setLiveIsStop', true)
    // }, 10000)
    // setTimeout(() => {
    //   store.dispatch('setLiveIsStop', false)
    // }, 15000)
    // 一时停止时需要轮询
    watch(liveIsStop, val => {
      if (val) {
        setTimeout(() => {
          getEventsEnterInfoByStop()
        }, 5000)
      }
    })
    const getEventsEnterInfoByStop = async () => {
      await authApi
        .chatroom(id.value)
        .then(res => {
          if (route.meta.meeting && res.data.livePause) {
            setTimeout(() => {
              getEventsEnterInfoByStop()
            }, 5000)
          } else if (
            route.meta.meeting &&
            !res.data.livePause &&
            liveIsStop.value
          ) {
            updateEnterInfo(true, res)
          }
        })
        .catch(() => {
          if (route.meta.meeting) {
            setTimeout(() => {
              getEventsEnterInfoByStop()
            }, 5000)
          }
        })
    }

    let loadingIm = ref(false)

    // im Event
    const onTextMessageReceived = event => {
      event.data.forEach(message => {
        console.log(message)
        if (message.from !== store.getters.meetingeEnterInfo.userId) {
          const type = message.type
          const data = message.payload.data
          switch (type) {
            case TIM.TYPES.MSG_CUSTOM:
              switch (data) {
                case 'pause':
                  store.dispatch('setLiveIsStop', true)
                  break
                case 'resume':
                  if (liveIsStop.value) {
                    eventsEnter(true)
                  }
                  break
                default:
                  store.dispatch('setMsg', { type: 'custom', data: message })
                  break
              }

              break
            case TIM.TYPES.MSG_TEXT:
              store.dispatch('setMsg', { type: 'chat', data: message })
              break
            case TIM.TYPES.MSG_GRP_SYS_NOTICE:
              switch (message.payload.handleMessage) {
                case 'kick':
                  router.replace({
                    name: 'StreamingEnd',
                    query: {
                      type: 1
                    }
                  })
                  break

                default:
                  break
              }
              break
            default:
              break
          }
        }
        console.log(
          'demo | ' + (message.from || message.nick) + ' : ',
          message.payload.text
        )
      })
    }
    // event
    const eventsDetail = async () => {
      await authApi.eventsDetail(id.value).then(res => {
        console.log(res)
        if (route.meta.meeting) {
          store.dispatch('setMeetingEvent', res.data)
          // now > イベント終了時間 + 1h
          if (route.name === 'ChatRoom') {
            const now = new Date().getTime()
            const closeTime = new Date(res.data.endTime).getTime() + 3600000
            if (now > closeTime) {
              router.replace({ name: 'Error' })
            }
          }
        }
      })
    }
    // enterInfo
    // let test = true
    const updateEnterInfo = (stopFlag, res) => {
      const enterData = res.data
      if (new Date().getTime() <= new Date(enterData.endTime).getTime()) {
        enterData['ticket'] = {}
        if (enterData.ticket) {
          enterData.ticket.startTime = enterData.startTime //new Date().getTime() + 2 * 60 * 1000
          enterData.ticket.endTime = enterData.endTime //new Date().getTime() + 3 * 60 * 1000 + 15000
          enterData.ticket.index = enterData.index
        }
      }
      store.dispatch('setMetingeEnterInfo', enterData)
      nextTick(() => {
        store.dispatch('setLiveIsStop', enterData.livePause)
      })
      // if (stopFlag) {
      //   store.dispatch('setLiveIsStop', false)
      // }
      // if (test) {
      //   enterData.livePause = true
      //   setTimeout(() => {
      //     store.dispatch('setLiveIsStop', false)
      //   }, 10000)
      //   test = false
      // }
    }
    const eventsEnter = async stopFlag => {
      await authApi
        .chatroom(id.value)
        .then(res => {
          if (route.meta.meeting) {
            console.log(res)
            updateEnterInfo(stopFlag, res)
          }
        })
        .catch(error => {
          console.error(error)
          const status = error.response.status
          switch (status) {
            default:
              router.replace({ name: 'Error' })
              break
          }
        })
    }
    watch(updateKey, () => {
      store.dispatch('setMetingeEnterInfo', null)
      eventsEnter()
    })
    // im
    let imLoginFlag = false
    let onUnmountedFlag = false
    const imLogin = () => {
      // login
      store.getters.im
        .login({
          userID: store.getters.meetingeEnterInfo.userId,
          userSig: store.getters.meetingeEnterInfo.userSig
        })
        .then(function(imResponse) {
          if (onUnmountedFlag) {
            store.getters.im
              .logout()
              .then(function(imResponse) {
                console.log(imResponse.data) // 登出成功
              })
              .catch(function(imError) {
                console.warn('logout error:', imError)
              })
            hideLoading()
            return false
          }
          imLoginFlag = true
          store.getters.im
            .joinGroup({
              groupID: String(store.getters.meetingeEnterInfo.roomId)
            })
            .then(function(imResponse) {
              switch (imResponse.data.status) {
                case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                  break
                case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                  break
                case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                  break
                default:
                  break
              }
            })
          if (imResponse.data.repeatLogin === true) {
            hideLoading()
            loadingIm.value = false
          }
        })
        .catch(() => {
          hideLoading()
          loadingIm.value = false
        })
    }
    const setProfile = () => {
      store.getters.im
        .updateMyProfile({
          nick: store.getters.meetingUser.nickName,
          avatar: `https://ui-avatars.com/api/?name=${encodeURI(
            store.getters.meetingUser.nickName
          )}&color=fff&background=${creatColorByNickName(
            store.getters.meetingUser.nickName
          )}` //store.getters.meetingUser.avatar
        })
        .then(function(imResponse) {
          console.log(imResponse.data) // 成功
        })
        .catch(function(imError) {
          console.warn('im | setMyProfile | failed', imError) // 设置资料失败的相关信息
        })
    }
    const creatIM = () => {
      if (!store.getters.im) {
        // tim式
        const tim = TIM.create({
          SDKAppID: store.getters.meetingeEnterInfo.appId
        })
        store.dispatch('setIm', tim)
        if (process.env.NODE_ENV === 'production') {
          tim.setLogLevel(1)
        } else {
          tim.setLogLevel(0)
        }

        tim.on(TIM.EVENT.MESSAGE_RECEIVED, function(event) {
          console.log(event)
          onTextMessageReceived(event)
        })
        tim.on(TIM.EVENT.SDK_READY, function() {
          setProfile()
          hideLoading()
          loadingIm.value = false
        })
        tim.on(TIM.EVENT.KICKED_OUT, () => {
          router.replace({ name: 'Error' })
        })
        imLogin()
      }
    }
    // userinfo
    const userInit = async () => {
      await authApi
        .profile()
        .then(res => {
          if (route.meta.meeting) {
            store.dispatch('setMeetingUser', res.data)
          }
        })
        .catch(error => {
          const status = error.response.status
          switch (status) {
            case 401:
              addRefreshJWT(userInit)
              break

            default:
              break
          }
        })
    }

    const init = async () => {
      await eventsEnter()
      await eventsDetail()
      await userInit()
      if (route.meta.meeting && store.getters.meetingeEnterInfo) {
        showLoading()
        loadingIm.value = true
        if (store.getters.im) {
          imLogin()
        } else {
          creatIM()
        }
      }
    }

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      onUnmountedFlag = true
      console.log(store.getters.im)
      if (store.getters.im && imLoginFlag) {
        // const roomID = store.getters.im._roomID
        store.getters.im
          .logout()
          .then(function(imResponse) {
            console.log(imResponse.data) // 登出成功
          })
          .catch(function(imError) {
            console.warn('logout error:', imError)
          })
      }
      store.dispatch('setMsg', null)
      store.dispatch('setMeetingEvent', null)
      store.dispatch('setMetingeEnterInfo', null)
      // store.dispatch('setIm', null)
      store.dispatch('setMeetingUser', null)
    })
    return { view, id }
  }
})
</script>

<style scoped></style>
