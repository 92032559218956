<template>
  <ion-app>
    <ion-router-outlet />
    <div>{{ loadingShow }}</div>
    <alert
      v-model:isOpen="updateExists"
      text="新しいバージョンがあります"
      @ok="refreshApp"
      :hasClose="false"
      ok-text="更新"
    ></alert>
  </ion-app>
  <div
    class="common-loading flexCenter dflex"
    v-if="loadingShow"
    @click="clickLoading"
  >
    <div class="common-loading-box">
      <div class="dib loader-box">
        <div class="loader"></div>
      </div>
      <div class="dib loader-text-box">ローディング</div>
    </div>
  </div>
  <div id="imageLoading"></div>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { defineComponent } from 'vue'
import Alert from './components/Alert'
import { authApi } from './api'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Alert
  },
  data() {
    return {
      registration: null,
      updateExists: false
    }
  },
  created() {
    // if (this.$store.getters.token) {
    //   this.$store.dispatch('checkDelay')
    // }
    this.checkPayInfo()
    window.popStateDetected = false
    window.addEventListener('popstate', () => {
      window.popStateDetected = true
    })

    if (this.token && this.$route.name !== 'Entrance') {
      this.getEventExpress()
    }

    // window.onbeforeunload = () => {
    //   if (this.$route.meta.meeting) {
    //     return '本当に遷移しますか'
    //   }
    //   return undefined
    // }
    // setInterval(() => {
    //   this.$store.dispatch('checkDelay')
    // }, 15000)
    // document.addEventListener('swUpdated', this.updateAvailable, { once: true })
  },
  computed: {
    loadingShow() {
      return this.$store.getters.loadingShow
    },
    token() {
      return this.$store.getters.token
    }
  },
  watch: {
    token: {
      handler: function(val) {
        if (val && this.$route.name !== 'Entrance') {
          this.getEventExpress()
        }
      },
      deep: true
    }
  },
  methods: {
    getEventExpress() {
      authApi.joined().then(res => {
        console.log(res)
        if (res.data.length > 0) {
          this.$store.dispatch('setEventExpress', res.data[0].eventExpress)
          this.eventId = res.data[0].id
        }
      })
    },
    checkPayInfo() {
      const payFrontUrl = localStorage.getItem('payFrontUrl')
      const payFrontUrlTime = localStorage.getItem('payFrontUrlTime')
      if (payFrontUrl && payFrontUrlTime) {
        const now = new Date().getTime()
        const payFrontUrlTimeNumber = Number(payFrontUrlTime)
        if (now - payFrontUrlTimeNumber < 600000) {
          localStorage.removeItem('payFrontUrl')
          localStorage.removeItem('payFrontUrlTime')
          window.location.href = payFrontUrl
        }
      }
    },
    updateAvailable(event) {
      this.registration = event.detail
      setTimeout(() => {
        this.updateExists = true
      }, 500)
    },
    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    clickLoading(e) {
      e.preventDefault()
    }
  }
})
</script>
