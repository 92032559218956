import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'
import 'vue-neat-modal/dist/vue-neat-modal.css'
import './assets/css/common.css'
import store from './store'
import Toaster from '@meforma/vue-toaster'

// import TWebLive from 'tweblive'

/* Theme variables */
import './theme/variables.css'

import './registerServiceWorker'

const app = createApp(App)
  .use(store)
  .use(IonicVue)
  .use(router)
  .use(Toaster, {
    position: 'top'
  })
const token = localStorage.getItem('token') || ''
const userType = localStorage.getItem('userType') || ''
store.dispatch('setToken', token)
store.dispatch('setUserType', userType)

router.isReady().then(() => {
  app.mount('#app')
})
console.log = (function(log) {
  return process.env.NODE_ENV == 'development' ? log : function() {}
})(console.log)
// app.config.globalProperties.$TWebLive = TWebLive
