// const userApiBaseUrl = 'http://192.168.11.230:8080'
const userApiBaseUrl = 'https://api.channel.online-talk.jp'
const liverApiBaseUrl = '/'
export default {
  userApiBaseUrl: userApiBaseUrl,
  liverApiBaseUrl: liverApiBaseUrl,
  googleAuthClientId: '',
  facebookAppId: '',
  lineClientId: '',
  lineRedirectUri: '',
  elepayPublishableKey: ''
}
