<template>
  <Modal
    max-width="320px"
    :modelValue="isOpen"
    @update:modelValue="closeDialog($event)"
    :align-y="alignY"
    :clickOut="clickOut"
    modal-transition="slide-up"
    @afterLeave="afterLeave"
  >
    <div class="common-alert-body">
      <ion-icon
        :icon="closeOutline"
        class="common-alert-close-ico"
        @click="close"
        v-if="hasClose"
      ></ion-icon>
      <div v-if="useHTML" v-html="text"></div>
      <div v-else>{{ text }}</div>
      <div v-if="type === 'confirm'" key="confirm" class="clearfix">
        <button type="button" class="common-alert-btn type-2 fl" @click="ok">
          {{ okText }}
        </button>
        <button
          type="button"
          class="common-alert-btn type-2 fr"
          @click="cancel"
        >
          {{ cancelText }}
        </button>
      </div>
      <div v-else key="alert">
        <button
          type="button"
          class="common-alert-btn"
          @click="ok"
          v-show="!isLoading"
        >
          {{ okText }}
        </button>
        <button type="button" class="common-alert-btn" v-show="isLoading">
          <ion-spinner name="bubbles" class="ion-spinner"></ion-spinner>
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue'
import { Modal } from 'vue-neat-modal'
import { closeOutline } from 'ionicons/icons'
import { IonIcon, IonSpinner } from '@ionic/vue'

export default defineComponent({
  name: 'Alert',
  props: {
    isOpen: { type: Boolean, default: false },
    alignY: { type: String, default: 'center' },
    okText: { type: String, default: '' },
    cancelText: { type: String, default: '' },
    text: { type: String, default: '' },
    clickOut: { type: Boolean, default: false },
    type: { type: String, default: `alert` },
    hasClose: { type: Boolean, default: true },
    useHTML: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },
  data() {
    return {
      closeOutline
    }
  },
  mounted() {},
  //   emits: ['update:isOpen'],
  components: {
    Modal,
    IonSpinner,
    IonIcon
  },
  methods: {
    ok() {
      this.$emit('ok')
    },
    cancel() {
      this.$emit('cancel')
    },
    close() {
      this.$emit('update:isOpen', false)
    },
    afterLeave() {
      this.$emit('afterLeave')
    },
    closeDialog(e) {
      this.$emit('update:isOpen', e)
    }
  }
})
</script>

<style scoped>
.common-alert-close-ico {
  position: absolute;
  z-index: 2;
  right: 15px;
  top: 15px;
  font-size: 18px;
}
.common-alert-btn.type-2 {
  width: 45%;
}
.common-alert-btn {
  width: 100%;
  height: 41px;
  background-color: #e05193;
  border-radius: 4px;
  margin-top: 20px;
  color: #fff;
}
.common-alert-body {
  background-color: #8a8a8a;
  font-size: 16px;
  position: relative;
  z-index: 1;
  padding: 30px 40px;
  text-align: left;
  color: #fff;
  border-radius: 10px;
}
ion-spinner {
  --color: #ffffff;
}
</style>
