<template>
  <ion-page>
    <ion-router-outlet />
  </ion-page>
</template>

<script>
import { IonRouterOutlet, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'User',
  components: {
    IonRouterOutlet,
    IonPage
  }
  // ionViewWillLeave() {
  //   alert(1)
  // }
})
</script>

<style scoped></style>
