import { createAPI } from './create-api'
import auth from './module/auth'
import { showLoading, hideLoading } from '../utils/utils'
import store from '../store'

const api = createAPI()
//请求拦截器
api.interceptors.request.use(
  config => {
    const data = config.data || {}
    let shouldJWT = config.shouldJWT || data.shouldJWT
    if (shouldJWT && store.getters.token) {
      config.headers['Authorization'] = `Bearer ${store.getters.token}`
    }
    if (data.shouldJWT !== undefined) {
      delete config.data.shouldJWT
    }
    const noLoading = config.noLoading
    console.log(config)
    if (!noLoading) {
      showLoading()
    }
    return config
  },
  error => {
    const config = error.response?.config || {}
    const noLoading = config.noLoading
    if (!noLoading) {
      hideLoading()
    }
    return Promise.reject(error)
  }
)
api.interceptors.response.use(
  response => {
    const config = response.config
    const noLoading = config.noLoading
    if (!noLoading) {
      hideLoading()
    }
    return response
  },
  error => {
    const config = error.response?.config || {}
    const noLoading = config.noLoading
    if (!noLoading) {
      hideLoading()
    }
    return Promise.reject(error)
  }
)
export const authApi = auth(api)
